import { ArrowNarrowRightIcon } from "@heroicons/react/outline"
import * as React from "react"
import Layout from "../../components/layout"
import Laptops from "../../images/sfds/mockup.jpeg"
import Iphones from "../../images/sfds/fullscreen.jpeg"
import Resources from "../../images/sfds/resources.jpeg"
import Admissions from "../../images/sfds/admissions.jpeg"

const SFDS = () => {
  return (
    <Layout>
      <div className="w-full mx-auto my-24 md:w-4/5">
        <div className="flex flex-col items-baseline justify-center h-96">
          <h1 className="text-5xl font-black md:text-6xl">
            <span className="highlight px-0.5">
              St. Francis de Sales Parish School
            </span>
          </h1>
          <p className="mt-4 text-lg">
            Marketing Website and Parent Resource Portal
          </p>
          <div className="flex h-8 mt-4">
            <p className="px-4 py-2 mr-2 text-xs font-bold text-white bg-gray-400 rounded-full">
              web
            </p>
            <p className="px-4 py-2 mr-2 text-xs font-bold text-white bg-gray-400 rounded-full">
              UX Design
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-10 my-10">
          <div className="grid grid-cols-1 gap-10 p-10 bg-gray-100 rounded-lg md:grid-cols-2 md:gap-16">
            <h4 className="text-xl font-bold">ABOUT</h4>
            <div>
              <p className="text-lg">
                A new website for St. Francis de Sales Parish School that is
                designed to be a marketing website and parent resource portal.
              </p>
              <div className="grid grid-cols-2 gap-4 mt-6">
                <p className="font-semibold">January 2024</p>
                <p>NextJS, PayloadCMS, TailwindCSS</p>
                <p className="font-semibold"></p>
                <p>
                  <strong>Client:</strong> St. Francis de Sales Parish School
                </p>
              </div>
            </div>
          </div>

          <a
            href="https://stfparishschool.org"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center p-10 text-xl font-bold text-center bg-gray-100 rounded-lg group"
          >
            Visit the Site{" "}
            <span className="w-6 ml-2">
              <ArrowNarrowRightIcon />
            </span>
          </a>

          <img className="col-span-2 rounded-lg" src={Laptops} alt="mockup" />
          <div className="grid grid-cols-1 gap-10 p-10 bg-gray-100 rounded-lg md:grid-cols-2 md:gap-16">
            <h4 className="text-xl font-bold">OBJECTIVE</h4>
            <div>
              <p className="text-lg">
                The objective of this project was to create a new website
                focused on marketing this private school to potential parents
                and students. The website also needed to be a resource for
                current parents and students. With those requirements in mind,
                the website was designed to focus on marketing the school while
                include top level navigational elements for existing parents and
                students.
              </p>
            </div>
          </div>
          {/* <div className="grid grid-cols-1 gap-10 p-10 bg-gray-100 rounded-lg md:grid-cols-2 md:gap-16">
            <h4 className="text-xl font-bold">SOLUTION</h4>
            <div>
              <p className="text-lg">
                The solution is a progressive web application that utilizes the
                Vimeo API to bring in the vast archive of videos and make them
                easily discoverable. It also brings in three live streams from
                HCTV's station that are easily found by users.
                <br />
                <br />
                It was found that a PWA would be the best solution for this
                problem because it can provide an app like feel and the ability
                for the user to install the app on some platforms.
              </p>
            </div>
          </div> */}
          <div className="grid grid-cols-2 gap-4">
            <img className="rounded-lg " src={Iphones} alt="mockup" />
            <div className="grid gap-4">
              <img className="rounded-lg " src={Resources} alt="mockup" />
              <img className="rounded-lg " src={Admissions} alt="mockup" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SFDS
